import React, { Component } from 'react';

import Loader from '../Components/Loader';
import Globals from '../Globals';

class ReportingNissan extends Component {

  constructor(props) {
    super(props);

    this.state = {
      UrlReporting: null,
    }
  }

  componentDidMount() {
    fetch(localStorage.ClientApiURL + '/Info', {
      method: 'GET',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'APIKey': Globals.API_KEY,
        'IDClient': localStorage.user_IDClient,
        'Login': localStorage.user_login,
        'Password': localStorage.user_password
      },
    })
    .then((response) => {
      if (!response.ok) {
        throw Error(response.statusText);
      }
      return response.json();
    })
    .then(json => {
      this.setState({
        UrlReporting: 'http://'+json.UrlReporting,
      }, () => {
        if (this.state.UrlReporting) {
          this.refs.formReporting.submit()
        }
        else {
          alert('Une erreur s\'est produite');
        }
      });
    })
    .catch((error) => {
      console.log(error)
    });
  }

  render() {

    return (
      <div>
        <h1 className='text-center' style={{ marginTop: 200 }}>Ouverture du reporting en cours ...</h1>
        <Loader style={{ marginTop: 50 }} />

        <form method="POST" action={this.state.UrlReporting + '/Login.aspx'} ref="formReporting">
          <input type='hidden' name='FROM' value='CRM' />

          <input type='hidden' name='LoginWeb' value={localStorage.user_login} />
          <input type='hidden' name='Password' value={localStorage.user_password} />

          <input type='hidden' name='IDCompany' value='1' />
          <input type='hidden' name='IDPeople' value={localStorage.user_IDClient} />
          <input type='hidden' name='IDSite' value='NULL' />
          {this.props.params.type === "VNVO" ?
              <input type='hidden' name='GOTO' value={`~/Prospection/SuiviLeads.aspx?IDMenu=72`} />
          :
              <input type='hidden' name='GOTO' value={`~/Prospection/SuiviLeadsAPV.aspx?IDMenu=72`} />
          }

        </form>
      </div>
    );
  }
}

export default ReportingNissan;
