import Globals from './Globals'

import moment from 'moment'

var lodash = require('lodash')

var Auth = {
    refreshing: false,

    login(IDClient, login, password, cb) {
        cb = arguments[arguments.length - 1]
        if (localStorage.token && localStorage.user_IDClient && localStorage.user_password && localStorage.ClientApiURL) {
            if (cb) cb(true)
            this.onChange(true)
            return
        }
        pretendRequest(IDClient, login, password, (res) => {
            if (res.authenticated) {
                localStorage.token = res.token
                localStorage.user_IDClient = IDClient
                localStorage.user_login = login
                localStorage.user_password = password
                localStorage.user_IDUtilisateur = res.IDUtilisateur
                localStorage.last_login = moment().format()
                localStorage.version = Globals.VERSION // Si la version est changée, on oblige la reconnexion des utilisateurs
                localStorage.urlWally = Globals.URL_WALLY
                localStorage.usernameWally = Globals.USERNAME_WALLY
                localStorage.passwordWally = Globals.PASSWORD_WALLY

                updateLocalStorage(res) //Stockage dans le localstorage des paramètres réactualisés toutes les 24h

                localStorage.lastInfoCheck = Date.now() //Secondes
                localStorage.CodePays = res.CodePays
                localStorage.CodeLangue = res.CodeLangue
                localStorage.IsAgri = res.IsAgri
                localStorage.access_leadsApv = res.access_leadsApv
                localStorage.access_leadsVNVO = res.access_leadsVNVO

                delete localStorage.bypass_iFrame

                if (cb) cb(true)
                this.onChange(true)
            } else {
                if (cb) cb(false)
                this.onChange(false)
            }
        })
    },

    getToken() {
        return localStorage.token
    },

    logout(cb) {
        localStorage.clear()

        if (cb)
            cb()

        this.onChange(false)
    },

    loggedIn() {

        if (!localStorage.version || localStorage.version !== Globals.VERSION) {
            this.logout()
        }
        return (!!localStorage.token && !!localStorage.user_IDClient && !!localStorage.user_password && !!localStorage.ClientApiURL)
    },

    refreshInfo() {
        this.refreshing = true
        console.log('refresh info')
        pretendRequest(localStorage.user_IDClient, localStorage.user_login, localStorage.user_password, (res) => {
            if (res.authenticated && localStorage.user_IDUtilisateur == res.IDUtilisateur) { //On vérifie s'il n'y a pas eu déco/reco sous un autre compte
                //localStorage.token = res.token
                updateLocalStorage(res)
                localStorage.lastInfoCheck = Date.now()
                console.log('refresh info done')
            }
            this.refreshing = false
            console.log('refresh info end')
        })
    },

    onChange() { },
}

function pretendRequest(IDClient, login, password, cb) {

    var myHeaders = new Headers()
    myHeaders.append("Accept", "application/json")
    myHeaders.append("Content-Type", "application/json")
    myHeaders.append("APIKey", Globals.API_KEY)
    myHeaders.append("IDClient", IDClient)
    myHeaders.append("Login", login)
    myHeaders.append("Password", password)

    var ApiUrl = Globals.API_URL

    var parts = window.location.hostname.split('.')
    if (parts[0] === 'dev' || parts[1] === 'dev' || parts[0] === 'preprod' || parts[1] === 'preprod' || parts[0] === 'localhost') {
        ApiUrl = Globals.API_URL_DEV
    }

    if (parts[0] === 'devagri' || parts[1] === 'devagri') {
        ApiUrl = Globals.API_URL_DEV_AGRI
    }

    fetch(ApiUrl + '/info', {
        method: 'GET',
        headers: myHeaders,
    })
        .then((response) => {
            if (!response.ok) {
                throw Error(response.statusText)
            }
            return response.json()
        })
        .then(json => {
            if (!json.Utilisateur) {
                throw Error('Bad login')
            }

            if (!json.Utilisateur.TypeProfilLead || json.Utilisateur.TypeProfilLead === 'Vendeur') { // Si pas de profil spécifique à eSeller Lead
                throw Error('Bad login')
            }

            var access_reporting = json.Utilisateur.EstPasserelle ? true : false
            if (access_reporting === false) {
                var droitsReporting = lodash.filter(json.Utilisateur.Profil.Droits, (droit) => {
                    return droit.Code === '04GEN00004' && droit.CodeTypePrivilege === '001'
                })
                access_reporting = (droitsReporting.length >= 1) ? true : false
            }

            var access_redistribuer = json.Utilisateur.EstPasserelle ? true : false
            if (access_redistribuer === false) {
                var droitsRedistribuer = lodash.filter(json.Utilisateur.Profil.Droits, (droit) => {
                    return droit.Code === '02ACT00005' && droit.CodeTypePrivilege === '001'
                })
                access_redistribuer = (droitsRedistribuer.length >= 1) ? true : false
            }

            var access_affectationUtilisateur = json.Utilisateur.EstPasserelle ? true : false
            if (access_affectationUtilisateur === false) {
                var droitsAffectationUtilisateur = lodash.filter(json.Utilisateur.Profil.Droits, (droit) => {
                    return droit.Code === '05GEN00002' && droit.CodeTypePrivilege === '001'
                })
                access_affectationUtilisateur = (droitsAffectationUtilisateur.length >= 1) ? true : false
            }

            var access_creationRelance = json.Utilisateur.EstPasserelle ? true : false
            if (access_creationRelance === false) {
                var droitsCreationRelance = lodash.filter(json.Utilisateur.Profil.Droits, (droit) => {
                    return droit.Code === '05GEN00003' && droit.CodeTypePrivilege === '001'
                })
                access_creationRelance = (droitsCreationRelance.length >= 1) ? true : false
            }

            var access_superviseurBDC = json.Utilisateur.EstPasserelle ? true : false
            if (access_superviseurBDC === false) {
                var droitsSuperviseurBDC = lodash.filter(json.Utilisateur.Profil.Droits, (droit) => {
                    return droit.Code === '05GEN00004' && droit.CodeTypePrivilege === '001'
                })
                access_superviseurBDC = (droitsSuperviseurBDC.length >= 1) ? true : false
            }

            let isHybridNissan = false
            if (json.BDGp.indexOf("BDGpNi") == -1) {
                isHybridNissan = lodash.some(json.Utilisateur.Sites, site =>
                    lodash.some(site.Marques, marque => marque.Description.toLowerCase() === "nissan")
                )
            }

            cb({
                authenticated: true,
                token: Math.random().toString(36).substring(7),
                ClientApiURL: json.ClientApiURL + '/api',
                username: json.Utilisateur.Nom,
                IDUtilisateur: json.Utilisateur.IDUtilisateur,
                profil: json.Utilisateur.TypeProfilLead,
                initiales: json.Utilisateur.Initiale,
                access_reporting: access_reporting,
                access_redistribuer: access_redistribuer,
                access_affectationUtilisateur: access_affectationUtilisateur,
                access_creationRelance: access_creationRelance,
                access_leadsApv: json.Utilisateur.AccesLeadAPV,
                access_leadsVNVO: json.Utilisateur.AccesLeadVNVO,
                EstPasserelle: json.Utilisateur.EstPasserelle,
                ServeurBDGp: json.ServeurBDGp,
                BDGp: json.BDGp,
                WebGp: json.WebGp,
                NomGp: json.NomGp,
                EstSuperviseur: json.Utilisateur.EstSuperviseur,
                access_superviseurBDC: access_superviseurBDC,
                eSellerLeadAtelierMailRapport: json.eSellerLeadAtelierMailRapport,
                esellerLeadEditRapportCloture: json.esellerLeadEditRapportCloture,
                esellerLeadCampagne: json.esellerLeadCampagne,
                BDCTraitementLeadAmontChefVente: json.BDCTraitementLeadAmontChefVente,
                DPOContactEnabled: json.DPOContactEnabled,
                BDCTraitementLeadsAPV: json.BDCTraitementLeadsAPV === false ? false : true,
                ModuleEmailSMS: json.ModuleEmailSMS,
                CodePays: json.CodePays,
                CodeLangue: json.CodeLangue,
                IsAgri: json.IsAgri,
                IsVDL: json.IsVDL,
                IsMoto: json.IsMoto,
                GestionVIP: json.GestionVIP,
                ReportingTransformationCyclevente: json.ReportingTransformationCyclevente,
                eSellerLeadFormatTelFR: json.eSellerLeadFormatTelFR,
                IsHybridNissan: isHybridNissan,
            })
        })
        .catch((error) => {
            cb({ authenticated: false })
        })
}

function updateLocalStorage(res) { //Cette fonction gère les paramètres stockés dans le localstorage qui sont réactualisés toutes les 24h
    localStorage.ClientApiURL = res.ClientApiURL
    localStorage.user_name = res.username
    localStorage.user_profil = res.profil
    localStorage.user_initiales = res.initiales
    localStorage.access_reporting = res.access_reporting
    localStorage.access_redistribuer = res.access_redistribuer
    localStorage.access_affectationUtilisateur = res.access_affectationUtilisateur
    localStorage.access_creationRelance = res.access_creationRelance
    localStorage.access_superviseurBDC = res.access_superviseurBDC
    localStorage.user_EstPasserelle = res.EstPasserelle
    localStorage.WebGp = res.WebGp
    localStorage.NomGp = res.NomGp
    localStorage.ServeurBDGp = res.ServeurBDGp
    localStorage.BDGp = res.BDGp
    localStorage.EstSuperviseur = res.EstSuperviseur
    localStorage.eSellerLeadAtelierMailRapport = res.eSellerLeadAtelierMailRapport
    localStorage.esellerLeadEditRapportCloture = res.esellerLeadEditRapportCloture
    localStorage.esellerLeadCampagne = res.esellerLeadCampagne
    localStorage.BDCTraitementLeadAmontChefVente = res.BDCTraitementLeadAmontChefVente
    localStorage.BDCTraitementLeadsAPV = res.BDCTraitementLeadsAPV //Si true, un DBC doit traiter préalablement les leads APV business (Prise de RDV/Demande de devis/Demande de dépannage)
    localStorage.ModuleEmailSMS = res.ModuleEmailSMS
    localStorage.DPOContactEnabled = res.DPOContactEnabled
    localStorage.CodePays = res.CodePays
    localStorage.CodeLangue = res.CodeLangue
    localStorage.IsAgri = res.IsAgri
    localStorage.IsVDL = res.IsVDL
    localStorage.GestionVIP = res.GestionVIP
    localStorage.access_leadsApv = res.access_leadsApv
    localStorage.access_leadsVNVO = res.access_leadsVNVO
    localStorage.IsMoto = res.IsMoto
    localStorage.ReportingTransformationCyclevente = res.ReportingTransformationCyclevente
    localStorage.eSellerLeadFormatTelFR = res.eSellerLeadFormatTelFR
    localStorage.IsHybridNissan = res.IsHybridNissan

    if (localStorage.BDGp.indexOf("BDGpNi") != -1) { //Ceci est déjà fait avant même la connexion (pour le logo nissan sur la page de login), mais le faire aussi ici permet que nissan soit détecté aussi en dev (localhost...)
        localStorage.style = "nissan"
        localStorage.IsNissan = "true"
    } else if (res.IsAgri) {
        localStorage.style = "magir"
    }

}

export default Auth