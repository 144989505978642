import React, { Component } from 'react';
import { Link } from 'react-router';
import HistoriqueProspect from './Pages/HistoriqueProspect.js'
import Scheduler from './Pages/Scheduler.js'
import NewsModal from './Pages/News/Modal'
import SkyLight from 'react-skylight';
import { browserHistory } from 'react-router'
import {MobileOnlyView, CustomView, isMobileOnly} from "react-device-detect";
import {IsCrawlHway} from './Utils'

import ReactGA from 'react-ga';

class Header extends Component {

  //handle change of page and popup closure
  handleProspectRedirect(attr, value){
    this.refs.modalHistoriqueProspect.hide();
    browserHistory.push('/prospect/' + value);
  }

  getNbLeads(type){
    return this.props.nbLeads[type] ? this.props.nbLeads[type] : 0;
  }

  render() {
    const currentLocation = window.location.pathname;
    const splitLocation = currentLocation.split('/');
    const baseLocation = splitLocation[1] ? splitLocation[1] : '';

    const accessReporting = JSON.parse(localStorage.getItem("access_reporting") || "false")
    const accessLeadAPV = JSON.parse(localStorage.getItem("access_leadsApv") || "false")
    const accessLeadsVNVO =  JSON.parse(localStorage.getItem("access_leadsVNVO") || "false")
    const isNissan = JSON.parse(localStorage.getItem("IsNissan") || "false")
    const isHybridNissan = JSON.parse(localStorage.getItem("IsHybridNissan") || "false")
    const isAgri = JSON.parse(localStorage.getItem("IsAgri") || "false")
    const isMoto = JSON.parse(localStorage.getItem("IsMoto") || "false")

    const showGeneralReporting = isNissan || (!isNissan && !isAgri && !isMoto)
    const showNissanReporting = isNissan || isHybridNissan

    return (
      <div>

      <nav className={"navbar navbar-default navbar-fixed-top hidden-print " + (localStorage.style === "nissan" ? "nissan" : "")}>
        <div className="container-fluid">
          <div className="navbar-header">
            <button type="button" className="collapsed navbar-toggle" data-toggle="collapse" data-target="#bs-example-navbar-collapse-6" aria-expanded="false">
              <span className="sr-only">Menu</span>
              <span className="icon-bar">
              </span>
              <span className="icon-bar">
              </span>
              <span className="icon-bar">
              </span>
            </button>
            <Link to={'/dashboard'} className="navbar-brand">
              {localStorage.style === "nissan" ?
                <div className="navbar-title">
                  <img src="/img/logo_nissan_blanc.png" role="presentation" />
                  SMIT Lead
                </div>
              :
                <img src={localStorage.style === "magir" ? "/img/Logo_Magir_blanc.png" : "/img/logo_eSeller-lead_blanc.png"} role="presentation" />
              }
            </Link>

            <MobileOnlyView>
                <a href="#" className="dropdown-toggle" data-toggle="dropdown" role="button" aria-haspopup="true" aria-expanded="false">
                  <div className="user-box">
                    <div className="profil-picture">
                      {localStorage.user_profil === 'Conseiller' ?
                        <img src="/img/Conseiller.png" role="presentation" />
                      : null}

                      {localStorage.user_profil === 'BDC' || localStorage.user_profil === 'WelcomePro' ?
                        <img src="/img/OPERATRICE.png" role="presentation" />
                      : null}

                      {localStorage.user_profil === 'ChefDesVentes' ?
                        <div className='initiales'>{localStorage.user_initiales}</div>
                      : null}
                    </div>
                    <div className="profil-names">
                      {localStorage.user_name} {localStorage.user_profil ? "(" + localStorage.user_profil + ")" : null}
                      &nbsp;<img src="/img/Arrow_Yellow.svg" className="profil-toggle" role='presentation' />
                    </div>
                  </div>
                </a>
                <ul className="dropdown-menu" style={{left:'auto',right:60}}>
                  <li><Link to={'/logout'}>Déconnexion <i className="icon-power icons"></i></Link></li>
                </ul>
            </MobileOnlyView>

          </div>


          <div className="collapse navbar-collapse" id="bs-example-navbar-collapse-6">
            <ul className="nav navbar-nav navbar-nav-main">
              {localStorage.user_profil !== 'Magasinier' && localStorage.IsAgri !== 'true' ?
                <li className={currentLocation === '/prospect' ? 'active' : ''}>
                  <Link to={'/prospect'} className="text-center" onClick={() => ReactGA.event({category: 'Menu', action: 'Créer un lead'})}>
                    Créer un lead
                  </Link>
                </li>
              : null}
              {/* TODO: Le === 'undefined' sert à ce que les utilisateurs déjà connectés au moment de la mise en prod n'aient pas leur menu VN/VO qui disparaissent et soient obligés de déconnecter / reconnecter
                * A supprimer à partir de 24h après mise en prod (pareil pour dashboard) */}
              {localStorage.access_leadsVNVO === 'true' || localStorage.access_leadsVNVO === undefined || localStorage.access_leadsVNVO === 'undefined' ?
                <li className={(currentLocation === '/leads/LeadsARedistribuer' || currentLocation === '/leads/AffairesEncoursBDC' || currentLocation === '/leads/AffairesEncoursVendeur' || currentLocation === '/leads/AffairesGagnees' || currentLocation === '/leads/AffairesClotures') ? 'active' : ''}>
                  <Link to={'/leads/LeadsARedistribuer'} className="text-center" onClick={() => ReactGA.event({category: 'Menu', action: 'Suivi des leads VN / VO'})}>
                    {localStorage.IsVDL === 'true' ? 'Suivi des leads VDL' 
                    :localStorage.IsAgri === 'true' ? 'Suivi des leads Matériels'
                    :'Suivi des leads VN / VO'} 
                    {localStorage.user_profil === 'BDC' && this.props.nbLeads !== null ? " ("+(this.getNbLeads("VN") + this.getNbLeads("VO"))+")" : null}
                  </Link>
                </li>
              : null}
              {localStorage.access_leadsApv === 'true' ?
                <li className={currentLocation.indexOf('/leadsAtelier') !== -1 ? 'active' : ''}>
                  <Link to={localStorage.eSellerLeadAtelierMailRapport === 'true' ? 
                              '/leadsAtelier/TypeAtelier' 
                            :localStorage.user_profil === "Conseiller" || localStorage.access_redistribuer !== "true" ?
                              '/leadsAtelier/AffairesEncours/Prise de RDV' 
                            : 
                              '/leadsAtelier/AffairesEncours/ATraiter'} 
                    className="text-center" onClick={() => ReactGA.event({category: 'Menu', action: 'Suivi leads Atelier'})}>
                    {localStorage.IsAgri === 'true' ? 'Suivi des leads Atelier' : 'Suivi leads APV'}
                    {localStorage.user_profil === 'BDC' && this.props.nbLeads !== null ? " ("+this.getNbLeads("Atelier")+")" : null}
                  </Link>
                </li>
              : null}
              {localStorage.IsVDL === 'true' ?
                <li className={(baseLocation === 'leadsAccessoires') ? 'active' : ''}>
                  <Link to={'/leadsAccessoires/AffairesEncours'} className="text-center" onClick={() => ReactGA.event({category: 'Menu', action: 'Leads Accessoires'})}>
                    Suivi leads Accessoires
                  </Link>
                </li>
              : null}
              {localStorage.user_profil !== 'Magasinier' && localStorage.IsAgri !== 'true' && localStorage.IsMoto !== 'true' ?
                <li className={currentLocation === '/actions' ? 'active' : ''}>
                  <Link to={'/actions'} className="text-center" onClick={() => ReactGA.event({category: 'Menu', action: 'Action à faire'})}>
                    Action à faire
                  </Link>
                </li>
              : null}
              {localStorage.esellerLeadCampagne && localStorage.esellerLeadCampagne === 'true' && localStorage.user_profil !== 'Conseiller' && localStorage.user_profil !== 'Magasinier' && localStorage.IsAgri !== 'true' && localStorage.IsMoto !== 'true' ?
                <li className={currentLocation === '/campagnes' ? 'active' : ''}>
                  <Link to={'/campagnes'} className="text-center">
                    Campagnes
                  </Link>
                </li>
              : null}
              {accessReporting &&
                  <li className="dropdown">
                    <a href="#" className="dropdown-toggle" data-toggle="dropdown" role="button" aria-haspopup="true" aria-expanded="false">Reporting <span className="caret"></span></a>
                    <ul className="dropdown-menu">
                      {showGeneralReporting &&
                        <li><Link to={'/reporting'} className="text-center" target="_blank" style={{ paddingTop: '12px', paddingBottom: '12px', height: 'auto' }}>Reporting</Link></li>
                      }
                      {showNissanReporting &&
                          <>
                            {accessLeadsVNVO &&
                                <li><Link to={'/reporting/nissan/VNVO'} className="text-center" target="_blank" style={{ paddingTop: '12px', paddingBottom: '12px', height: 'auto' }}>Suivi
                              d'activité VN/VO</Link></li>
                            }

                              {accessLeadAPV &&
                                  <li><Link to={'/reporting/nissan/APV'} className="text-center" target="_blank" style={{ paddingTop: '12px', paddingBottom: '12px', height: 'auto' }}>Suivi
                                      d'activité APV</Link></li>
                              }

                          </>
                      }
                      </ul>
                  </li>
              }
            </ul>

            <CustomView condition={!isMobileOnly}>
            <ul className="nav navbar-nav navbar-right">
              <li className="dropdown">
                <a href="#" className="dropdown-toggle" data-toggle="dropdown" role="button" aria-haspopup="true" aria-expanded="false">
                  <div className="user-box">
                    <div className="profil-picture">
                      {localStorage.user_profil === 'Conseiller' ?
                        <img src="/img/Conseiller.png" role="presentation" />
                      : null}

                      {localStorage.user_profil === 'BDC' || localStorage.user_profil === 'WelcomePro' ?
                        <img src="/img/OPERATRICE.png" role="presentation" />
                      : null}

                      {localStorage.user_profil === 'ChefDesVentes' ?
                        <div className='initiales'>{localStorage.user_initiales}</div>
                      : null}
                    </div>
                    <div className="profil-names">
                      <div className="line-name">{localStorage.user_name}</div>
                      <div className="line-profile">
                      {
                        localStorage.user_profil ? 
                          localStorage.user_profil === "ChefDesVentes" ? 
                            "(Manager)"
                          :
                            "(" + localStorage.user_profil + ")"
                        :null}
                      </div>
                    </div>
                    <img src="/img/Arrow_Yellow.svg" className="profil-toggle" role='presentation' />
                  </div>
                </a>
                <ul className="dropdown-menu">
                  <li><a href="#" onClick={(e) => {this.refs.newsModal.fetchNewsAndShow(); e.preventDefault();}}>News <i className=" icon-envelope-open icons"></i></a></li>
                  <li><a onClick={() => {this.refs.modalAgendaHeader.show(); ReactGA.event({category: 'Menu', action: 'Calendrier vendeurs'}); }}>Calendrier vendeurs <i className="icon-calendar icons"></i></a></li>
                  {/*<li><Link to={'/holidays'}>Gestion des absences <i className="icon-calendar icons"></i></Link></li>*/}
                  <li><a onClick={() => {this.refs.modalHistoriqueProspect.show(); ReactGA.event({category: 'Menu', action: 'Profils consultés'}); }}>Profils consultés <i className="icon-people icons"></i></a></li>
                    {localStorage.user_profil === 'ChefDesVentes' ?
                      <li><Link to={'/gestionVendeurs'}>Gestion des vendeurs <i className="icon-organization icons"></i></Link></li>
                    : null}
                    {localStorage.user_profil === 'ChefDesVentes' ?
                      <li><Link to={'/objectifsVendeurs'}>Objectifs vendeurs <i className="icon-target icons"></i></Link></li>
                    : null}
                  {IsCrawlHway() ?
                    <li><Link to={'/hway'}>Hway affaires gagnées<i className="icon-trophy icons"></i></Link></li>
                  : null}
                  <li><Link to={'/holidays'}>Gestion des absences <i className="icon-event icons"></i></Link></li>
                  <li><Link to={'/logout'}>Déconnexion <i className="icon-power icons"></i></Link></li>
                </ul>
              </li>
            </ul>
            </CustomView>
          </div>
        </div>
      </nav>

      <SkyLight hideOnOverlayClicked ref="modalAgendaHeader" dialogStyles={{ width: '90%', left: '5%', marginLeft: '0%', height: '90%', top: '5%', marginTop: '0%', overflow: 'scroll', zIndex: 9999}}>
        <div className='inner-large-content'>
          <Scheduler />
        </div>
      </SkyLight>

      <SkyLight hideOnOverlayClicked ref="modalHistoriqueProspect" dialogStyles={{ width: '60%', left: '5%', marginLeft: '20%', height: '90%', top: '5%', marginTop: '0%', overflow: 'scroll', zIndex: 9999}}>
        <div className='inner-large-content'>
          <HistoriqueProspect onChange={this.handleProspectRedirect.bind(this)}/>
        </div>
      </SkyLight>

      <NewsModal ref="newsModal"/>

      </div>
    );
  }
}

export default Header;